<template>
  <section
    class="support-container"
    :style="isPopup ? { filter: 'blur(30px)' } : { filter: 'none' }"
  >
    <div class="support-content" id="support-content">
      <h3 class="header title">Our Partners & Supporters</h3>
      <div class="logo mt-10">
        <img src="../assets/support/nvidia.png" class="pt-2 nvidia" id="" />
        <img src="../assets/support/human.png" class="human-icon" id="human" />
        <!-- <img src="../assets/support/near.png" id="near" class="mb-2 near" /> -->
        <img src="../assets/support/concordium.png" class="concordium-icon" />
        <img src="../assets/support/bfg.png" class="bfg-icon" />
        <!-- <img src="../assets/support/polygon.png" class="poly" id="mobile" /> -->
        <!-- <img src="../assets/support/Graph.png" class="graph" id="mobile" /> -->
        <!-- <img src="../assets/support/caduceus.png" class="graph" id="mobile" /> -->
        <!-- <img src="../assets/support/bfg.png" class="hedera" id="mobile" /> -->
        <!-- <img src="../assets/support/ethereum.png" class="eth" id="mobile" /> -->
        <!-- <img
          src="../assets/support/decentraland.png"
          class="decentraland"
          id="mobile"
        /> -->
      </div>
      <div class="logo second">
        <!-- <img src="../assets/support/polygon.png" class="poly" id="poly" /> -->
        <!-- <img src="../assets/support/caduceus.png" id="cad" class="mb-2 cad" /> -->
        <!-- <img src="../assets/support/Graph.png" class="graph" id="bfg" /> -->
        <!-- <img src="../assets/support/bfg.png" class="bfg-icon" /> -->
        <!-- <img src="../assets/support/ethereum.png" class="eth" id="eth" /> -->
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "SupportSection",
  computed: {
    ...mapState({
      isPopup: (state) => state.isPopup,
    }),
  },
};
</script>
<style scoped>
#mobile {
  display: none;
}
.support-container {
  width: 100%;
  overflow: hidden;
  padding-top: 10%;
}

.support-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 50px !important;
  text-align: center;
  font-family: "Orbitron", sans-serif !important;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 75%;
}

/* .logo > img {
  width: 20%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
} */

.sub-title {
  width: 80%;
  margin: 10px 0px 0px 0px;
  text-align: center;
  font-family: "Chakra Petch", sans-serif;
  color: #e0e0e0;
  font-size: 20px;
  font-weight: 500;
}

.header {
  background: linear-gradient(90.45deg, #00ffbf -2.93%, #1657dc 95.78%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif !important;
  font-weight: 800;
}

.bfg-icon {
  width: 16% !important;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.concordium-icon {
  width: 22% !important;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.human-icon {
  width: 18% !important;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.decentraland {
  width: 30% !important;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.eth {
  width: 30% !important;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.second {
  width: 60%;
}
.near {
  width: 15% !important;
  margin-right: 3%;
}
@media only screen and (max-width: 600px) {
  .second {
    display: none;
  }
  #mobile {
    display: block;
    display: flex;
    width: 100%;
  }
  .title {
    font-size: 30px !important;
    text-align: center;
    font-family: "Orbitron", sans-serif !important;
    font-weight: 800;
  }
  .logo {
    width: 100%;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    justify-content: flex-start;
  }
  .logo > img {
    width: 40%;
  }
  .bfg-icon {
    width: 30% !important;
  }
  .human-icon {
    width: 40% !important;
  }
  .eth {
    width: 40% !important;
  }
  .decentraland {
    width: 40% !important;
  }
  .hedera {
    width: 30% !important;
  }
  .poly {
    width: 36% !important;
  }
  .graph {
    width: 30% !important;
  }
  .near {
    width: 30% !important;
    margin-right: 10%;
  }
  .concordium-icon {
    width: 50% !important;
  }
}
img {
  transition: transform 0.2s;
}
img:hover {
  transform: scale(1.2);
}
.hedera {
  width: 22%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.poly {
  width: 24%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.graph {
  width: 20%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.nvidia {
  width: 20%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
.cad {
  width: 20%;
  height: 100%;
  object-fit: contain;
  margin-right: 40px;
  cursor: pointer;
}
</style>
